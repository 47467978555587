import React from 'react'

import {
  Box,
  Button,
  Editable,
  EditableProps,
  Flex,
  Text,
} from '@rhythm/components'
import moment from 'moment'

import {
  baseStyles,
  baseStylesWithoutBorder,
  ColumnHeader,
  RowTitle,
  TransmissionReportValuesGrid,
  usePatchTransmissionReportDevice,
  useTransmissionReportContext,
} from '../../../../../features/transmissionReports'
import {
  VendorDataOverrides,
  VtDeviceLead,
  VtDeviceMeasurement,
} from '../../../../../lib/api'
import { DeviceLeadLocation } from '../../../../../types'
import { VENDOR_DATA_OVERRIDE_KEYS } from '../../../../../utils/constants/transmissionReports'
import displayMonitoredValue from '../../../../../utils/displayMonitoredValue'

interface VTDeviceWithPreviousTxData extends VtDeviceLead {
  previousLead?: VtDeviceLead
}

interface CellProps extends EditableProps {
  name: string
  isBorderRequired: boolean
}
const Cell = (props: CellProps) => {
  const currentStyles = props.isBorderRequired
    ? baseStyles
    : baseStylesWithoutBorder

  return (
    <Flex {...currentStyles}>
      <Editable w="full" fontSize="sm" {...props} />
    </Flex>
  )
}

const EmptyCell = ({
  isBorderRequired = false,
}: {
  isBorderRequired: boolean
}) => {
  const currentStyles = isBorderRequired ? baseStyles : baseStylesWithoutBorder

  return (
    <Flex {...currentStyles} paddingLeft="md">
      {displayMonitoredValue('')}
    </Flex>
  )
}

const UnEditableTextCell = ({
  value,
  isBorderRequired,
}: {
  value: string
  isBorderRequired: boolean
}) => {
  const currentStyles = isBorderRequired ? baseStyles : baseStylesWithoutBorder

  return (
    <Flex {...currentStyles}>
      <Text fontSize="sm" color="gray.500" ml={2}>
        {displayMonitoredValue(value)}
      </Text>
    </Flex>
  )
}

function mergePreviousLeads(
  deviceLeads: VtDeviceLead[],
  prevDeviceLeads: VtDeviceLead[],
): VTDeviceWithPreviousTxData[] {
  const prevLeadsMap = new Map(
    prevDeviceLeads.map(lead => [lead.leadLocation, lead]),
  )

  return deviceLeads.map(lead => ({
    ...lead,
    previousLead: prevLeadsMap.get(lead.leadLocation) ?? undefined,
  }))
}

const DeviceMonitoredValuesTable = (): React.ReactElement => {
  const { transmissionReport, isLoading: isLoadingTransmissionReport } =
    useTransmissionReportContext()
  const { mutate: patchTransmissionReportDevice } =
    usePatchTransmissionReportDevice()

  const [hidePreviousReportValues, setHidePreviousReportValues] =
    React.useState(true)

  const handleSubmit = (data: {
    name: keyof VendorDataOverrides
    value: string
    leadId?: VendorDataOverrides['deviceLeadId']
  }) => {
    const { name, value, leadId } = data
    if (transmissionReport?.id && VENDOR_DATA_OVERRIDE_KEYS.includes(name)) {
      const vendorDataOverrides = {
        [name]: value,
        ...(!!leadId && { deviceLeadId: leadId }),
      }
      patchTransmissionReportDevice({
        transmissionReportId: transmissionReport.id,
        vendorDataOverrides,
      })
    }
  }

  const {
    deviceLeads,
    deviceMeasurement,
    previousTxDeviceLeads,
    previousTxDeviceMeasurement,
  } = transmissionReport ?? {}

  const mergedDeviceLeads = mergePreviousLeads(
    deviceLeads ?? [],
    previousTxDeviceLeads ?? [],
  )

  const raLead = mergedDeviceLeads?.find(
    lead => lead.leadLocation === DeviceLeadLocation.RA,
  )
  const rvLead = mergedDeviceLeads?.find(
    lead => lead.leadLocation === DeviceLeadLocation.RV,
  )
  const lvLead = mergedDeviceLeads?.find(
    lead => lead.leadLocation === DeviceLeadLocation.LV,
  )

  return (
    <Box>
      <Box display={'flex'} justifyContent="space-between" py={4}>
        <Text fontSize={'lg'} fontWeight={'bold'}>
          Monitored Values
        </Text>
        <Box
          textColor={'blue.500'}
          fontWeight={'semibold'}
          cursor={'pointer'}
          onClick={() => {
            setHidePreviousReportValues(!hidePreviousReportValues)
          }}
        >
          {hidePreviousReportValues ? 'Show' : 'Hide'} Last Report&rsquo;s
          Values
        </Box>
      </Box>
      <TransmissionReportValuesGrid
        key={`device-leads-${deviceMeasurement?.id}${
          !deviceLeads && '-loading'
        }`}
        gridTemplateColumns="1fr repeat(3, 20%)"
        gridTemplateRows="repeat(7, minmax(50px, auto))"
        loading={{
          isLoading: isLoadingTransmissionReport,
          numOfColumns: 4,
          numOfRows: 6,
        }}
      >
        <ColumnHeader>Device</ColumnHeader>
        <ColumnHeader>&nbsp;&nbsp;&nbsp;RA</ColumnHeader>
        <ColumnHeader>&nbsp;&nbsp;&nbsp;RV</ColumnHeader>
        <ColumnHeader>&nbsp;&nbsp;&nbsp;LV</ColumnHeader>

        <RowTitle>Sensing (mV)</RowTitle>
        {[raLead, rvLead, lvLead].map(
          (deviceLead: VTDeviceWithPreviousTxData | undefined, _i) => (
            <Flex
              {...baseStyles}
              key={`sensing-flex-${deviceMeasurement?.id}-${_i}`}
            >
              <Box
                key={`div-sensing-${deviceMeasurement?.id}-${_i}`}
                display={'flex'}
                flexDirection={'column'}
              >
                <Cell
                  key={`sensing-${deviceMeasurement?.id}-${_i}`}
                  name="sensing"
                  defaultValue={displayMonitoredValue(deviceLead?.sensing)}
                  onSubmit={value =>
                    handleSubmit({
                      name: 'deviceLeads.sensing',
                      value,
                      leadId: deviceLead?.leadId,
                    })
                  }
                  fontSize="sm"
                  fontWeight={'bold'}
                  isBorderRequired={false}
                />
                {!hidePreviousReportValues &&
                  deviceLead?.sensing !== deviceLead?.previousLead?.sensing && (
                    <UnEditableTextCell
                      isBorderRequired={false}
                      value={
                        deviceLead?.previousLead?.sensing
                          ? `${displayMonitoredValue(
                              deviceLead?.previousLead?.sensing,
                            )} (${moment(
                              deviceLead?.previousLead?.createdAt,
                            ).format('MM/DD/YYYY')})`
                          : '-'
                      }
                    />
                  )}
              </Box>
            </Flex>
          ),
        )}

        <RowTitle>Lead Impedance (&#8486;)</RowTitle>
        {[raLead, rvLead, lvLead].map(
          (deviceLead: VTDeviceWithPreviousTxData | undefined, _i) => (
            <Flex
              {...baseStyles}
              key={`impedance-flex-${deviceLead?.leadId}-${_i}`}
            >
              <Box
                key={`div-sensing-${deviceLead?.leadId}-${_i}`}
                display={'flex'}
                flexDirection={'column'}
              >
                <Cell
                  key={`impedance-${deviceLead?.leadId}-${_i}`}
                  name="impedance"
                  defaultValue={displayMonitoredValue(deviceLead?.impedance)}
                  onSubmit={value =>
                    handleSubmit({
                      name: 'deviceLeads.impedance',
                      value,
                      leadId: deviceLead?.leadId,
                    })
                  }
                  isBorderRequired={false}
                />
                {!hidePreviousReportValues &&
                  deviceLead?.impedance !==
                    deviceLead?.previousLead?.impedance && (
                    <UnEditableTextCell
                      isBorderRequired={false}
                      value={
                        deviceLead?.previousLead?.impedance
                          ? `${displayMonitoredValue(
                              deviceLead?.previousLead?.impedance,
                            )} (${moment(
                              deviceLead?.previousLead?.createdAt,
                            ).format('MM/DD/YYYY')})`
                          : '-'
                      }
                    />
                  )}
              </Box>
            </Flex>
          ),
        )}

        <RowTitle>Pacing Threshold (V@ms)</RowTitle>
        {[raLead, rvLead, lvLead].map(
          (deviceLead: VTDeviceWithPreviousTxData | undefined, _i) => (
            <Flex
              key={`threshold-pulsewidth-${deviceMeasurement?.id}-${_i}`}
              {...baseStyles}
              display="grid"
              gridTemplateColumns="50px 20px 50px"
            >
              <Editable
                name="threshold"
                fontSize="sm"
                textAlign="center"
                defaultValue={displayMonitoredValue(deviceLead?.threshold)}
                onSubmit={value =>
                  handleSubmit({
                    name: 'deviceLeads.threshold',
                    value,
                    leadId: deviceLead?.leadId,
                  })
                }
              />
              <span style={{ margin: '0 1px' }}>@</span>
              <br />
              <Editable
                name="pulsewidth"
                fontSize="sm"
                textAlign="center"
                defaultValue={displayMonitoredValue(deviceLead?.pulsewidth)}
                onSubmit={value =>
                  handleSubmit({
                    name: 'deviceLeads.pulsewidth',
                    value,
                    leadId: deviceLead?.leadId,
                  })
                }
              />
              <Box></Box>
              <Box></Box>

              {!hidePreviousReportValues &&
                (deviceLead?.threshold !==
                  deviceLead?.previousLead?.threshold ||
                  deviceLead?.pulsewidth !==
                    deviceLead?.previousLead?.pulsewidth) && (
                  <Box my={2}>
                    <UnEditableTextCell
                      value={
                        deviceLead?.previousLead?.threshold &&
                        deviceLead?.previousLead?.pulsewidth
                          ? `${displayMonitoredValue(
                              deviceLead?.previousLead?.threshold,
                            )} @ ${displayMonitoredValue(
                              deviceLead?.previousLead?.pulsewidth,
                            )} (${moment(
                              deviceLead?.previousLead?.createdAt,
                            ).format('MM/DD/YYYY')})`
                          : '-'
                      }
                      isBorderRequired={false}
                    />
                  </Box>
                )}
            </Flex>
          ),
        )}

        <RowTitle>High Voltage Impedance (&#8486;)</RowTitle>
        {[undefined, deviceMeasurement?.hvImpedance, undefined].map(
          (data: VtDeviceMeasurement['hvImpedance'] | undefined, _i) => (
            <Flex
              {...baseStyles}
              key={`hvImpedance-flex-${deviceMeasurement?.id}-${_i}`}
            >
              {typeof data !== 'undefined' ? (
                <Box>
                  <Cell
                    key={`hvImpedance-${deviceMeasurement?.id}-${_i}`}
                    name="hvImpedance"
                    defaultValue={displayMonitoredValue(data)}
                    onSubmit={value =>
                      handleSubmit({
                        name: 'deviceMeasurement.hvImpedance',
                        value,
                      })
                    }
                    isBorderRequired={false}
                  />
                  {!hidePreviousReportValues &&
                    previousTxDeviceMeasurement?.hvImpedance !== data && (
                      <UnEditableTextCell
                        isBorderRequired={false}
                        value={
                          previousTxDeviceMeasurement?.hvImpedance
                            ? `${displayMonitoredValue(
                                previousTxDeviceMeasurement?.hvImpedance,
                              )} (${moment(
                                previousTxDeviceMeasurement?.createdAt,
                              ).format('MM/DD/YYYY')})`
                            : '-'
                        }
                      />
                    )}
                </Box>
              ) : (
                <EmptyCell
                  key={`hvImpedance-${deviceMeasurement?.id}-${_i}`}
                  isBorderRequired={false}
                />
              )}
            </Flex>
          ),
        )}

        <RowTitle>AF Burden (%)</RowTitle>
        {[deviceMeasurement?.afBurden, undefined, undefined].map(
          (data: VtDeviceMeasurement['afBurden'] | undefined, _i) => (
            <Flex
              {...baseStyles}
              key={`afBurden-flex-${deviceMeasurement?.id}-${_i}`}
            >
              {typeof data !== 'undefined' ? (
                <Box>
                  <Cell
                    key={`afBurden-${deviceMeasurement?.id}-${_i}`}
                    name="afBurden"
                    defaultValue={displayMonitoredValue(data)}
                    onSubmit={value =>
                      handleSubmit({
                        name: 'deviceMeasurement.afBurden',
                        value,
                      })
                    }
                    isBorderRequired={false}
                  />
                  {!hidePreviousReportValues &&
                    previousTxDeviceMeasurement?.afBurden !== data && (
                      <UnEditableTextCell
                        isBorderRequired={false}
                        value={
                          previousTxDeviceMeasurement?.afBurden
                            ? `${displayMonitoredValue(
                                previousTxDeviceMeasurement?.afBurden,
                              )} (${moment(
                                previousTxDeviceMeasurement?.createdAt,
                              ).format('MM/DD/YYYY')})`
                            : '-'
                        }
                      />
                    )}
                </Box>
              ) : (
                <EmptyCell
                  key={`afBurden-${deviceMeasurement?.id}-${_i}`}
                  isBorderRequired={false}
                />
              )}
            </Flex>
          ),
        )}

        <RowTitle>Percent Paced (%)</RowTitle>
        {[
          {
            apAPacePercent: [
              deviceMeasurement?.apAPacePercent,
              previousTxDeviceMeasurement?.apAPacePercent,
            ],
          },
          {
            rvpRvPacePercent: [
              deviceMeasurement?.rvpRvPacePercent,
              previousTxDeviceMeasurement?.rvpRvPacePercent,
            ],
          },
          {
            lvpPercent: [
              deviceMeasurement?.lvpPercent,
              previousTxDeviceMeasurement?.lvpPercent,
            ],
          },
        ].map((data, _i) => {
          const [[overrideKey, defaultValue]] = Object.entries(data) as [
            [string, (string | null | undefined)[]],
          ]

          return (
            <Flex
              {...baseStyles}
              key={`percent-paced-flex-${deviceMeasurement?.id}-${_i}`}
            >
              <Box>
                <Cell
                  key={`percent-paced-${deviceMeasurement?.id}-${_i}`}
                  name="percent-paced"
                  defaultValue={displayMonitoredValue(defaultValue[0])}
                  onSubmit={value =>
                    handleSubmit({
                      name: `deviceMeasurement.${overrideKey}` as keyof VendorDataOverrides,
                      value,
                    })
                  }
                  isBorderRequired={false}
                />
                {!hidePreviousReportValues &&
                  defaultValue[0] !== defaultValue[1] && (
                    <UnEditableTextCell
                      value={
                        defaultValue[1] &&
                        previousTxDeviceMeasurement?.createdAt
                          ? `${displayMonitoredValue(defaultValue[1])} (
                      ${moment(previousTxDeviceMeasurement?.createdAt).format(
                        'MM/DD/YYYY',
                      )}
                    )`
                          : '-'
                      }
                      isBorderRequired={false}
                    />
                  )}
              </Box>
            </Flex>
          )
        })}
      </TransmissionReportValuesGrid>
    </Box>
  )
}

export default DeviceMonitoredValuesTable
