import { Box } from '@rhythm/components'
import moment from 'moment'

import { devicesVendorOptions } from '../../../../constants'
import {
  AccountOverviewQuery,
  AccountOverviewQueryColumnsEnum,
  AlertsQuery,
  AlertsQueryColumnsEnum,
  ReportType as AnalyticsReportType,
  BillableQuery,
  BillableQueryColumnsEnum,
  DeviceTypes,
  SchedulingQuery,
  SchedulingQueryColumnsEnum,
  SignatureStatusQuery,
  SignatureStatusQueryColumnsEnum,
  TransmissionReportDtoColorEnum,
} from '../../../../lib/api'
import { routes } from '../../../../routes'
import {
  PatientActiveStatusEnum,
  PatientStatusReasons,
  ReportType,
} from '../../../../types'

import { capitalizeFirstLetter } from './constants'
import { PatientTableCell } from './PatientTableCell'

export const billingColumnNameMapping: Record<string, string> = {
  accountName: 'Account Name',
  patientName: 'Patient Name',
  dateOfBirth: 'Date of Birth',
  ehrMrn: 'EHR-MRN',
  diagnosis: 'Diagnosis',
  dateOfService: 'Date of Service',
  signedDate: 'Signed Date',
  vendor: 'Vendor',
  deviceType: 'Device Type',
  modelName: 'Model Name',
  serialNumber: 'Serial Number',
  reportType: 'Report Type',
  nextScheduledDate: 'Next Scheduled Date',
  followingPractitioner: 'Following Practitioner',
  referringProvider: 'Referring Provider',
  professionalCptCode: 'Professional CPT Code',
  technicalCptCode: 'Technical CPT Code',
  signingPractitioner: 'Signing Practitioner',
}

export const defaultBillableQuery: BillableQuery = {
  dateRange: [
    moment(new Date()).subtract(2, 'days').format('YYYY-MM-DD'),
    moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'),
  ],
  columns: Object.keys(billingColumnNameMapping).map(
    key => key as BillableQueryColumnsEnum,
  ),
  clinicIds: [],
  fetchAll: false,
  limit: 50,
  offset: 0,
}

export const billingColumns: AnalyticsTableColumnProps[] = [
  {
    Header: billingColumnNameMapping['accountName'],
    accessor: 'accountName',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 240,
  },
  {
    Header: billingColumnNameMapping['patientName'],
    accessor: 'patientName',
    Cell: ({ row }) => (
      <PatientTableCell
        value={{
          name: row.original.patientName,
          id: row.original.patientId,
        }}
      />
    ),
  },
  {
    Header: billingColumnNameMapping['dateOfBirth'],
    accessor: 'dateOfBirth',
    width: 110,
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
  },
  {
    Header: billingColumnNameMapping['ehrMrn'],
    accessor: 'ehrMrn',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 120,
  },
  {
    Header: billingColumnNameMapping['diagnosis'],
    accessor: 'diagnosis',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 200,
  },
  {
    Header: billingColumnNameMapping['dateOfService'],
    accessor: 'dateOfService',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 125,
  },
  {
    Header: billingColumnNameMapping['signedDate'],
    accessor: 'signedDate',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 125,
  },
  {
    Header: billingColumnNameMapping['vendor'],
    accessor: 'vendor',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    hasInnerMenu: true,
    innerMenuOptions: devicesVendorOptions,
    width: 135,
  },
  {
    Header: billingColumnNameMapping['deviceType'],
    accessor: 'deviceType',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    hasInnerMenu: true,
    innerMenuOptions: Object.keys(DeviceTypes).map(key => {
      return {
        label: DeviceTypes[key as keyof typeof DeviceTypes] as string,
        value: DeviceTypes[key as keyof typeof DeviceTypes] as string,
      }
    }),
    width: 100,
  },
  {
    Header: billingColumnNameMapping['modelName'],
    accessor: 'modelName',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 140,
  },
  {
    Header: billingColumnNameMapping['serialNumber'],
    accessor: 'serialNumber',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 140,
  },
  {
    Header: billingColumnNameMapping['reportType'],
    accessor: 'reportType',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    hasInnerMenu: true,
    innerMenuOptions: Object.keys(ReportType).map(key => {
      return {
        value: key,
        label: capitalizeFirstLetter(
          ReportType[key as keyof typeof ReportType] as string,
        ),
      }
    }),
    width: 85,
  },
  {
    Header: billingColumnNameMapping['nextScheduledDate'],
    accessor: 'nextScheduledDate',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 120,
  },
  {
    Header: billingColumnNameMapping['followingPractitioner'],
    accessor: 'followingPractitioner',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    hasInnerMenu: true,
  },
  {
    Header: billingColumnNameMapping['referringProvider'],
    accessor: 'referringProvider',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
  },
  {
    Header: billingColumnNameMapping['professionalCptCode'],
    accessor: 'professionalCptCode',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
  },
  {
    Header: billingColumnNameMapping['technicalCptCode'],
    accessor: 'technicalCptCode',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
  },
  {
    Header: billingColumnNameMapping['signingPractitioner'],
    accessor: 'signingPractitioner',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
  },
]

export const accountOverviewColumnNameMapping: Record<string, string> = {
  accountName: 'Account Name',
  patientName: 'Patient Name',
  dateOfBirth: 'Date of Birth',
  ehrMrn: 'EHR-MRN',
  diagnosis: 'Diagnosis',
  vendor: 'Vendor',
  deviceType: 'Device Type',
  modelName: 'Model Name',
  serialNumber: 'Serial Number',
  patientStatus: 'Patient Status',
  patientStatusReason: 'Patient Status Reason',
  followingPractitioner: 'Following Practitioner',
  referringProvider: 'Referring Provider',
}

export type AnalyticsTableColumnProps = {
  Header: string
  accessor: string
  Cell?: ({ row, value }: { row: any; value: any }) => any
  hasInnerMenu?: boolean
  innerMenuOptions?: any[]
  width?: number
}

export const accountOverviewColumns: AnalyticsTableColumnProps[] = [
  {
    Header: accountOverviewColumnNameMapping['accountName'],
    accessor: 'accountName',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 240,
  },
  {
    Header: accountOverviewColumnNameMapping['patientName'],
    accessor: 'patientName',
    Cell: ({ row }) => (
      <PatientTableCell
        value={{
          name: row.original.patientName,
          id: row.original.patientId,
        }}
      />
    ),
  },
  {
    Header: accountOverviewColumnNameMapping['dateOfBirth'],
    accessor: 'dateOfBirth',
    width: 110,
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
  },
  {
    Header: accountOverviewColumnNameMapping['ehrMrn'],
    accessor: 'ehrMrn',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 120,
  },
  {
    Header: accountOverviewColumnNameMapping['diagnosis'],
    accessor: 'diagnosis',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 200,
  },
  {
    Header: accountOverviewColumnNameMapping['vendor'],
    accessor: 'vendor',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    hasInnerMenu: true,
    innerMenuOptions: devicesVendorOptions,
    width: 135,
  },
  {
    Header: accountOverviewColumnNameMapping['deviceType'],
    accessor: 'deviceType',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    hasInnerMenu: true,
    innerMenuOptions: Object.keys(DeviceTypes).map(key => {
      return {
        label: DeviceTypes[key as keyof typeof DeviceTypes] as string,
        value: DeviceTypes[key as keyof typeof DeviceTypes] as string,
      }
    }),
    width: 100,
  },
  {
    Header: accountOverviewColumnNameMapping['modelName'],
    accessor: 'modelName',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 140,
  },
  {
    Header: accountOverviewColumnNameMapping['serialNumber'],
    accessor: 'serialNumber',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 140,
  },
  {
    Header: accountOverviewColumnNameMapping['patientStatus'],
    accessor: 'patientStatus',
    hasInnerMenu: true,
    innerMenuOptions: Object.keys(PatientActiveStatusEnum)
      .filter(item => ['All', 'Active', 'Paused', 'Inactive'].includes(item))
      .map(key => {
        if (key === 'All') return
        return {
          label: capitalizeFirstLetter(
            PatientActiveStatusEnum[
              key as keyof typeof PatientActiveStatusEnum
            ] as string,
          ),
          value: PatientActiveStatusEnum[
            key as keyof typeof PatientActiveStatusEnum
          ] as string,
        }
      })
      .filter(Boolean),
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
  },
  {
    Header: accountOverviewColumnNameMapping['patientStatusReason'],
    accessor: 'patientStatusReason',
    hasInnerMenu: true,
    innerMenuOptions: PatientStatusReasons.map(reason => {
      return {
        label: reason,
        value: reason,
      }
    }),
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
  },
  {
    Header: accountOverviewColumnNameMapping['followingPractitioner'],
    accessor: 'followingPractitioner',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    hasInnerMenu: true,
  },
  {
    Header: accountOverviewColumnNameMapping['referringProvider'],
    accessor: 'referringProvider',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
  },
]

export const defaultAccountOverviewQuery: AccountOverviewQuery = {
  dateRange: [
    moment(new Date()).format('YYYY-MM-DD'),
    moment(new Date()).format('YYYY-MM-DD'),
  ],
  columns: Object.keys(accountOverviewColumnNameMapping).map(
    key => key as AccountOverviewQueryColumnsEnum,
  ),
  clinicIds: [],
  fetchAll: false,
  limit: 50,
  offset: 0,
}

export const alertColumnNameMapping: Record<string, string> = {
  accountName: 'Account Name',
  alertType: 'Alert Type',
  dateOfService: 'Date of Service',
  signedDate: 'Signed Date',
  patientName: 'Patient Name',
  dateOfBirth: 'Date of Birth',
  ehrMrn: 'EHR-MRN',
  diagnosis: 'Diagnosis',
  vendor: 'Vendor',
  deviceType: 'Device Type',
  modelName: 'Model Name',
  serialNumber: 'Serial Number',
  patientStatus: 'Patient Status',
  patientStatusReason: 'Patient Status Reason',
  followingPractitioner: 'Following Practitioner',
  referringProvider: 'Referring Provider',
}

export const defaultAlertsQuery: AlertsQuery = {
  dateRange: [
    moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'),
    moment(new Date()).format('YYYY-MM-DD'),
  ],
  columns: Object.keys(alertColumnNameMapping).map(
    key => key as AlertsQueryColumnsEnum,
  ),
  clinicIds: [],
  fetchAll: false,
  limit: 50,
  offset: 0,
}

export const alertsColumns: AnalyticsTableColumnProps[] = [
  {
    Header: alertColumnNameMapping['accountName'],
    accessor: 'accountName',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 240,
  },
  {
    Header: alertColumnNameMapping['alertType'],
    accessor: 'alertType',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 100,
    hasInnerMenu: true,
    innerMenuOptions: [
      TransmissionReportDtoColorEnum.Red,
      TransmissionReportDtoColorEnum.Yellow,
      TransmissionReportDtoColorEnum.Green,
    ].map(key => ({
      label: capitalizeFirstLetter(key),
      value: key,
    })),
  },
  {
    Header: alertColumnNameMapping['dateOfService'],
    accessor: 'dateOfService',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 125,
  },
  {
    Header: alertColumnNameMapping['signedDate'],
    accessor: 'signedDate',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 110,
  },
  {
    Header: alertColumnNameMapping['patientName'],
    accessor: 'patientName',
    Cell: ({ row }) => (
      <PatientTableCell
        value={{
          name: row.original.patientName,
          id: row.original.patientId,
        }}
      />
    ),
  },
  {
    Header: alertColumnNameMapping['dateOfBirth'],
    accessor: 'dateOfBirth',
    width: 110,
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
  },
  {
    Header: alertColumnNameMapping['ehrMrn'],
    accessor: 'ehrMrn',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 120,
  },
  {
    Header: alertColumnNameMapping['diagnosis'],
    accessor: 'diagnosis',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 200,
  },
  {
    Header: alertColumnNameMapping['vendor'],
    accessor: 'vendor',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    hasInnerMenu: true,
    innerMenuOptions: devicesVendorOptions,
    width: 135,
  },
  {
    Header: alertColumnNameMapping['deviceType'],
    accessor: 'deviceType',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    hasInnerMenu: true,
    innerMenuOptions: Object.keys(DeviceTypes).map(key => {
      return {
        label: DeviceTypes[key as keyof typeof DeviceTypes] as string,
        value: DeviceTypes[key as keyof typeof DeviceTypes] as string,
      }
    }),
    width: 100,
  },
  {
    Header: alertColumnNameMapping['modelName'],
    accessor: 'modelName',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 140,
  },
  {
    Header: alertColumnNameMapping['serialNumber'],
    accessor: 'serialNumber',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 140,
  },
  {
    Header: alertColumnNameMapping['patientStatus'],
    accessor: 'patientStatus',
    hasInnerMenu: true,
    innerMenuOptions: Object.keys(PatientActiveStatusEnum)
      .filter(item => ['All', 'Active', 'Paused', 'Inactive'].includes(item))
      .map(key => {
        if (key === 'All') return
        return {
          label: capitalizeFirstLetter(
            PatientActiveStatusEnum[
              key as keyof typeof PatientActiveStatusEnum
            ] as string,
          ),
          value: PatientActiveStatusEnum[
            key as keyof typeof PatientActiveStatusEnum
          ] as string,
        }
      })
      .filter(Boolean),
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
  },
  {
    Header: alertColumnNameMapping['patientStatusReason'],
    accessor: 'patientStatusReason',
    hasInnerMenu: true,
    innerMenuOptions: PatientStatusReasons.map(reason => {
      return {
        label: reason,
        value: reason,
      }
    }),
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
  },
  {
    Header: alertColumnNameMapping['followingPractitioner'],
    accessor: 'followingPractitioner',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    hasInnerMenu: true,
  },
  {
    Header: alertColumnNameMapping['referringProvider'],
    accessor: 'referringProvider',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
  },
]

export const signatureStatusColumnNameMapping: Record<string, string> = {
  followingPractitioner: 'Following Practitioner',
  redSigned: 'Red-Signed',
  redAwaitingSignature: 'Red-Awaiting Signature',
  yellowSigned: 'Yellow-Signed',
  yellowAwaitingSignature: 'Yellow-Awaiting Signature',
  greenSigned: 'Green-Signed',
  greenAwaitingSignature: 'Green-Awaiting Signature',
}

export const defaultSignatureStatusQuery: SignatureStatusQuery = {
  dateRange: [
    moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'),
    moment(new Date()).format('YYYY-MM-DD'),
  ],
  columns: Object.keys(alertColumnNameMapping).map(
    key => key as SignatureStatusQueryColumnsEnum,
  ),
  clinicIds: [],
  fetchAll: false,
  limit: 50,
  offset: 0,
}

export const signatureStatusColumns: AnalyticsTableColumnProps[] = [
  {
    Header: signatureStatusColumnNameMapping['followingPractitioner'],
    accessor: 'followingPractitioner',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    hasInnerMenu: true,
    width: 180,
  },
  {
    Header: signatureStatusColumnNameMapping['redSigned'],
    accessor: 'redSigned',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
  },
  {
    Header: signatureStatusColumnNameMapping['redAwaitingSignature'],
    accessor: 'redAwaitingSignature',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
  },
  {
    Header: signatureStatusColumnNameMapping['yellowSigned'],
    accessor: 'yellowSigned',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
  },
  {
    Header: signatureStatusColumnNameMapping['yellowAwaitingSignature'],
    accessor: 'yellowAwaitingSignature',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
  },
  {
    Header: signatureStatusColumnNameMapping['greenSigned'],
    accessor: 'greenSigned',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
  },
  {
    Header: signatureStatusColumnNameMapping['greenAwaitingSignature'],
    accessor: 'greenAwaitingSignature',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
  },
]

export const schedulingColumnNameMapping: Record<string, string> = {
  accountName: 'Account Name',
  patientName: 'Patient Name',
  dateOfBirth: 'Date of Birth',
  ehrMrn: 'EHR-MRN',
  diagnosis: 'Diagnosis',
  vendor: 'Vendor',
  deviceType: 'Device Type',
  modelName: 'Model Name',
  serialNumber: 'Serial Number',
  nextScheduledDate: 'Next Scheduled Date',
  scheduleType: 'Schedule Type',
}

export const defaultSchedulingQuery: SchedulingQuery = {
  dateRange: [
    moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'),
    moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
  ],
  columns: Object.keys(alertColumnNameMapping).map(
    key => key as SchedulingQueryColumnsEnum,
  ),
  clinicIds: [],
  fetchAll: false,
  limit: 50,
  offset: 0,
}

export const schedulingColumns: AnalyticsTableColumnProps[] = [
  {
    Header: schedulingColumnNameMapping['accountName'],
    accessor: 'accountName',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 240,
  },
  {
    Header: schedulingColumnNameMapping['patientName'],
    accessor: 'patientName',
    Cell: ({ row }) => (
      <PatientTableCell
        value={{
          name: row.original.patientName,
          id: row.original.patientId,
        }}
      />
    ),
  },
  {
    Header: schedulingColumnNameMapping['dateOfBirth'],
    accessor: 'dateOfBirth',
    width: 110,
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
  },
  {
    Header: schedulingColumnNameMapping['ehrMrn'],
    accessor: 'ehrMrn',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 100,
  },
  {
    Header: schedulingColumnNameMapping['diagnosis'],
    accessor: 'diagnosis',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 200,
  },
  {
    Header: schedulingColumnNameMapping['vendor'],
    accessor: 'vendor',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    hasInnerMenu: true,
    innerMenuOptions: devicesVendorOptions,
    width: 135,
  },
  {
    Header: schedulingColumnNameMapping['deviceType'],
    accessor: 'deviceType',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    hasInnerMenu: true,
    innerMenuOptions: Object.keys(DeviceTypes).map(key => {
      return {
        label: DeviceTypes[key as keyof typeof DeviceTypes] as string,
        value: DeviceTypes[key as keyof typeof DeviceTypes] as string,
      }
    }),
    width: 100,
  },
  {
    Header: schedulingColumnNameMapping['modelName'],
    accessor: 'modelName',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 140,
  },
  {
    Header: schedulingColumnNameMapping['serialNumber'],
    accessor: 'serialNumber',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 140,
  },
  {
    Header: schedulingColumnNameMapping['nextScheduledDate'],
    accessor: 'nextScheduledDate',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
    width: 120,
  },
  {
    Header: schedulingColumnNameMapping['scheduleType'],
    accessor: 'scheduleType',
    Cell: ({ value }: { value: string }) => value || <Box pl={8}>-</Box>,
  },
]

export const fetchAnalyticsProps = (pathname: string) => {
  switch (pathname) {
    case routes.physician.analytics.billing:
      return {
        title: 'Billing',
        key: AnalyticsReportType.Billable,
        columns: billingColumns,
        columnNameMapping: billingColumnNameMapping,
        dateRangeTitle: 'Select Service Date Range',
        defaultQuery: defaultBillableQuery,
      }
    case routes.physician.analytics.accountOverview:
      return {
        title: 'Account Overview',
        key: AnalyticsReportType.AccountOverview,
        columns: accountOverviewColumns,
        columnNameMapping: accountOverviewColumnNameMapping,
        dateRangeTitle: 'Select Patient Date Range',
        defaultQuery: defaultAccountOverviewQuery,
      }
    case routes.physician.analytics.alerts:
      return {
        title: 'Alerts',
        key: AnalyticsReportType.Alerts,
        columns: alertsColumns,
        columnNameMapping: alertColumnNameMapping,
        dateRangeTitle: 'Select Signed Date Range',
        defaultQuery: defaultAlertsQuery,
      }
    case routes.physician.analytics.signatureStatus:
      return {
        title: 'Signature Status',
        key: AnalyticsReportType.SignatureStatus,
        columns: signatureStatusColumns,
        columnNameMapping: signatureStatusColumnNameMapping,
        dateRangeTitle: 'Select Transmission Date Range',
        defaultQuery: defaultSignatureStatusQuery,
      }
    case routes.physician.analytics.scheduling:
      return {
        title: 'Scheduling',
        key: AnalyticsReportType.Scheduling,
        columns: schedulingColumns,
        columnNameMapping: schedulingColumnNameMapping,
        dateRangeTitle: 'Select Next Scheduled Date Range',
        defaultQuery: defaultSchedulingQuery,
      }
    default:
      return {
        title: '',
        key: AnalyticsReportType.AccountOverview,
        columns: [],
        columnNameMapping: {},
        dateRangeTitle: '',
        defaultQuery: defaultAccountOverviewQuery,
      }
  }
}
