import React, { useEffect, useMemo, useState } from 'react'

import { Divider, Flex, Heading, VStack } from '@rhythm/components'

import {
  TransmissionAlertsCards,
  useTransmissionReportContext,
} from '../../../../../features/transmissionReports'
import { ClinicianInterpretationCards } from '../../../../../features/transmissionReports/components/TransmissionReportClinicalInterpretation'
import {
  TransmissionReportDto,
  VtLeadVendor,
  VtVendor,
} from '../../../../../lib/api'
import { REASONS_FOR_MONITORING_OPTIONS } from '../../../../../utils/constants/transmissionReports'
import { PractitionerOption } from '../../TransmissionReportPage'
import BillingCard from '../BillingCard'
import CountersValuesCard from '../CountersValuesCard'
import DeviceBatteryCard from '../DeviceBatteryCard'
import DeviceDemographicsCard from '../DeviceDemographicsCard'
import DeviceMonitoredValuesCard from '../DeviceMonitoredValuesCard'
import DeviceProgrammedParamsAndMonitoredValuesCard from '../DeviceProgrammedParametersMonitoredValuesCard/DeviceProgrammedParametersMonitoredValuesCard'
import PatientDemographicsCard from '../PatientDemographicsCard'
import SignOffCard from '../SignOffCard'
import TransmissionNotesCard from '../TransmissionNotesCard'
import TransmissionSummaryCard from '../TransmissionSummaryCard'

const TransmissionReportDetails = ({
  vendors,
  leadVendors,
  trReport,
  practitionerOptions,
}: {
  vendors?: VtVendor[]
  leadVendors?: VtLeadVendor[]
  trReport?: TransmissionReportDto
  practitionerOptions?: PractitionerOption[]
}): React.ReactElement => {
  const { transmissionReport, setCurrentTransmission } =
    useTransmissionReportContext()
  const [callDelete, setCallDelete] = useState(false)

  useEffect(() => {
    if (trReport) {
      setCurrentTransmission(trReport)
    }
  }, [trReport, setCurrentTransmission])

  const isRemote = transmissionReport?.transmissionType === 'remote'
  const isILRDevice = transmissionReport?.device?.deviceType === 'ILR'
  const isInClinic = transmissionReport?.transmissionType === 'in-clinic'

  const ehrIntegration = transmissionReport?.account?.ehrIntegration ?? false

  const isSecondSign = useMemo(
    () => transmissionReport?.account?.sentToEhr === 'secondSign',
    [transmissionReport?.account?.sentToEhr],
  )

  const handleNoteDelete = (noteId: string) => setCallDelete(prev => !prev)

  useEffect(() => {
    if (transmissionReport?.patient) {
      const monitorReasonExists = REASONS_FOR_MONITORING_OPTIONS.some(
        e => e.value === transmissionReport?.patient?.monitorReason,
      )
      if (!monitorReasonExists) {
        transmissionReport.patient.monitorReason = ''
      }
    }
  }, [transmissionReport?.patient])

  return (
    <>
      <TransmissionSummaryCard transmissionReport={transmissionReport} />

      <Flex flexDirection="column" mt="5xl" mb="3xl">
        {/* <p>Is ILR Device {String(isILRDevice)}</p>
        <p>Is Remote {String(isRemote)}</p>
        <p>Is inclinic {String(isInClinic)}</p> */}
        <Heading variant="h6" mb="xl">
          Background
        </Heading>
        <VStack direction={{ base: 'column', md: 'row' }} spacing="xl">
          <PatientDemographicsCard
            key={transmissionReport?.patient?.id}
            transmissionReport={transmissionReport}
            ReasonForMonitorILR={isILRDevice}
            practitionerOptions={practitionerOptions}
            ehrIntegration={ehrIntegration}
          />
          <DeviceDemographicsCard
            key={`device-demographics-${transmissionReport?.id}`}
            transmissionReport={transmissionReport}
            vendors={vendors}
            leadVendors={leadVendors}
          />
        </VStack>
      </Flex>
      <Divider />
      <VStack mb={isRemote ? '5xl' : ''} spacing="xl" mt="3xl">
        <TransmissionAlertsCards callDelete={callDelete} />
      </VStack>
      {isRemote && (
        <VStack hidden={isILRDevice}>
          <DeviceMonitoredValuesCard />
        </VStack>
      )}
      {isInClinic && (
        <VStack hidden={isILRDevice} mt={'3xl'}>
          <DeviceProgrammedParamsAndMonitoredValuesCard />
        </VStack>
      )}
      {(isRemote || isInClinic) && (
        <VStack hidden={!isILRDevice}>
          <CountersValuesCard />
        </VStack>
      )}
      {(isRemote || isInClinic) && (
        <VStack mb={isILRDevice ? '1x1' : '5xl'} spacing="xl" mt="3xl">
          <DeviceBatteryCard />
        </VStack>
      )}
      <VStack hidden={isInClinic ? false : isILRDevice} spacing="xl" mt="3xl">
        <TransmissionNotesCard onDeleteNote={handleNoteDelete} />
        {isSecondSign && (
          <ClinicianInterpretationCards
            transmissionReport={transmissionReport}
          />
        )}
      </VStack>

      <VStack spacing="xl" mt={isRemote || isInClinic ? '3xl' : 'xl'}>
        {(isRemote || isInClinic) && <BillingCard />}
      </VStack>
      <VStack mb="3xl" spacing="xl" mt={'3xl'}>
        <SignOffCard transmissionReport={transmissionReport} />
      </VStack>
    </>
  )
}

export default TransmissionReportDetails
