import { useEffect, useState } from 'react'

import { OktaAuth } from '@okta/okta-auth-js'
import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'

import { globalConfig } from '../config'
import { Configuration, DefaultApi } from '../lib/api/generated-client'
import routes from '../routes/config'

interface apiData {
  status: number | string
  details: any
}

const useApi = () => {
  const [Api, setApi] = useState<DefaultApi>()

  const runtimeConfig = globalConfig.get()
  console.log({ runtimeConfig })

  const [isApiReady, setIsApiReady] = useState<boolean>()
  const [baseUrl, setBaseUrl] = useState<string>()

  useEffect(() => {
    if (runtimeConfig) {
      const axiosBaseUrl = runtimeConfig.API_SERVER ?? 'http://localhost:8001'
      const axiosGlobalConfig = new Configuration({
        basePath: axiosBaseUrl,
      })
      setBaseUrl(axiosBaseUrl)

      console.log({ runtimeConfig })
      const config = {
        clientId: runtimeConfig.OKTA_AUTH_CLIENT_ID,
        issuer: runtimeConfig.OKTA_AUTH_ISSUER,
        redirectUri: `${window.location.origin}${routes.login.callback}`,
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
      }

      const oktaAuth = new OktaAuth(config)

      const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
        // console.info(`[request] [${JSON.stringify(config)}]`) // for debugging
        const accessToken = oktaAuth.getAccessToken()
        if (accessToken) {
          config.headers = {
            Authorization: `Bearer ${accessToken}`,
          }
        } else {
          config.headers = {}
        }

        config.headers['Content-Type'] = 'application/json'

        return config
      }

      const onRequestError = (error: AxiosError): Promise<AxiosError> => {
        // console.error(`[request error] [${JSON.stringify(error)}]`)  // for debugging
        return Promise.reject(error)
      }

      const onResponse = (response: AxiosResponse): AxiosResponse => {
        // console.info(`[response] [${JSON.stringify(response)}]`)  // for debugging
        return response
      }

      const onResponseError = (error: AxiosError): Promise<AxiosError> => {
        // console.error(`[response error] [${JSON.stringify(error)}]`) // for debugging

        let data: apiData = error.response?.data as unknown as apiData

        if (!data) data = {} as apiData
        if (!data?.status) data.status = 404
        if (!data?.details) data.details = { message: 'Not Found' }

        // Just tracing errors for now
        if ((error.response?.data as apiData)?.status === 401) {
          // TODO: Handle 401 from API gateway
          // either redirect to login or request a new token via Okta
          console.trace(
            `API Unauthorized Error: ${JSON.stringify(
              (error.response?.data as apiData)?.details,
            )}`,
          )
        }

        return Promise.reject(error.response?.data ?? error)
      }

      const setupInterceptors = (
        axiosInstance: AxiosInstance,
      ): AxiosInstance => {
        axiosInstance.interceptors.request.use(onRequest, onRequestError)
        axiosInstance.interceptors.response.use(onResponse, onResponseError)
        return axiosInstance
      }

      const axiosConfig: AxiosRequestConfig = {
        baseURL: axiosBaseUrl,
      }

      const axios: AxiosInstance = setupInterceptors(Axios.create(axiosConfig))

      const ApiInstance = new DefaultApi(axios, axiosGlobalConfig, undefined)

      setApi(ApiInstance)
      setIsApiReady(true)
    }
  }, [runtimeConfig])

  return { Api, isApiReady, baseUrl }
}

export default useApi
